@import url('./styles/var.css');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
*{
  
  font-family: "Poppins", sans-serif !important;
}
body {
  
  background-color: var(--background);
  color: var(--text-primary);
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: "Poppins", sans-serif;
}
.wrapper {
  /* width: 100%; */
 /*  max-width: 1248px; */
  /* margin: 0 auto; */
}
/* search input */
.search-wrapper {
 /*  margin: 0px 0 ; */
  /* display: flex; */
  /* justify-content: space-between; */
  /* margin-left: 0rem; */
 /*  width: 100%; */

}

@media (max-width: 575px) {
  .search-input {
     /*  width: 100%; */
  }
  .search-wrapper {
   /*  justify-content: start;
    flex-wrap: wrap; */
  }
  /* .select {
      margin-top: 0em;
  } */
}
.search-input {
/*   background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0xNS44NTMgMTYuNTZjLTEuNjgzIDEuNTE3LTMuOTExIDIuNDQtNi4zNTMgMi40NC01LjI0MyAwLTkuNS00LjI1Ny05LjUtOS41czQuMjU3LTkuNSA5LjUtOS41IDkuNSA0LjI1NyA5LjUgOS41YzAgMi40NDItLjkyMyA0LjY3LTIuNDQgNi4zNTNsNy40NCA3LjQ0LS43MDcuNzA3LTcuNDQtNy40NHptLTYuMzUzLTE1LjU2YzQuNjkxIDAgOC41IDMuODA5IDguNSA4LjVzLTMuODA5IDguNS04LjUgOC41LTguNS0zLjgwOS04LjUtOC41IDMuODA5LTguNSA4LjUtOC41eiIvPjwvc3ZnPg==");
  background-color: var(--bg-offset);
  background-position: left 10px center;
  background-repeat: no-repeat;
  padding:15px;
  border-radius: 5px;
  border: 1px solid var(--border);
  color: var(--gray);
  transition: all .5s cubic-bezier(0.215, 0.61, 0.355, 1);
  outline: none; */

}
input::placeholder{
  font-size: larger;
}
.search-input:hover {
  box-shadow: 0px 0px 0px var(--border);
}

/* select from moderncss.dev */
select {
  
 /*  appearance: none !important;
  outline: none;
  background-color: var(--bg-offset);
  border-radius: 0.25em;
  border-width: 1px;
  border-style: solid;
  border-color: var(--border);
  padding: 15px 30px;
  margin: 0;
  margin-right: 1em;
  width: 100%;
  font-family: inherit;
  font-size: large !important; 
  cursor: inherit;
  line-height: inherit;
  width: 100%;
  color:rgb(100, 100, 190); */
}
.select {
  /* line-height: 1.3;
  background-color: transparent;
  display: grid;
  grid-template-areas: "select";
  align-items: center;
  position: relative;
  border-radius: 0.25em;
  transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1); */
  
}

select:hover {
/*   box-shadow: 0px 0px 0px var(--border); */
}

select::after {
  /* content: "";
  display: block;
  width: 0.8em;
  height: 0.5em;
  background-color: var(--text);
  clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  justify-self: end;
  margin-right: 1em; */
}

select,
.select:after {
 /*  grid-area: select; */
}
select:focus + .focus {
 /*  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border: 2px solid var(--text);
  border-radius: inherit; */
}
.container-search-shadow{
  box-shadow: 0px 0px 10px gray;
}

